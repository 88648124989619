<template>
	<div class="pagesbox">
		<div class="banner">
			<div class="banner-body flex">
				<div class="banner-body-title flex">
					<div style="width: 60%;align-items: initial;flex-direction: column;line-height: 0.50rem;"
						class="flex">
						<!-- <span style="font-size: 0.36rem; color: #FFFFFF;font-family: Source Han Sans CN;">力瀚新闻</span> -->
						<span
							style="font-size: 0.30rem;color: #fff;font-family: Source Han Sans CN;">关注力瀚最新资讯，了解私域新动态</span>
					</div>
				</div>
				<div class="banner-body-img flex">
					<img style="width: 2.70rem;" src="../assets/img/Journalismlogo.png">
				</div>
			</div>
			<img style="width: 100%;height: 100%;" src="../assets/img/Journalismbanner.png">
		</div>

		<div class="Journalism-body flex">
			<div class="Journalism-body-l">
				<div class="Journalism-body-l-cell flex" v-for="(item,index) in articlelist" :key="index">
					<img style="width: 2.10rem;" :src="item.img">
					<div class="Journalism-body-l-cell-r">
						<div class="L-cell-r-tit">{{item.title}}</div>
						<div class="L-cell-r-time">{{item.time}}</div>
						<div class="L-cell-r-time">{{item.lable}}</div>
						<div class="L-cell-r-but">
							查看更多</div>
						<!-- <el-button type="primary" size="mini" style="margin-top: 0.20rem;">查看更多</el-button> -->
					</div>
				</div>

				<el-pagination style="width: 100%;text-align: left;" class="elPagination" background layout="prev, pager, next" :total="1000">
				</el-pagination>
			</div>
			<div class="Journalism-body-r">
				<div class="Journalism-body-r-tit">
					热门文章
				</div>
				<div class="Journalism-body-r-cent">
					<div style="padding: 0.10rem;color: #666666;font-size: 0.12rem;border-bottom: 1px solid #cccccc5c;">
						美妆行业数字化转型，颜值经济 的私域沉淀</div>
					<div style="padding: 0.10rem;color: #666666;font-size: 0.12rem;border-bottom: 1px solid #cccccc5c">
						七大行业，搭建私域客户池的重 要性</div>
					<div style="padding: 0.10rem;color: #666666;font-size: 0.12rem;border-bottom: 1px solid #cccccc5c">
						私域搭配旅游，全渠道营销就该 这么玩！</div>
					<div style="padding: 0.10rem;color: #666666;font-size: 0.12rem;border-bottom: 1px solid #cccccc5c">
						旅游景区数字化转型，人群精准 营销是关键！</div>
					<div style="padding: 0.10rem;color: #666666;font-size: 0.12rem;border-bottom: 1px solid #cccccc5c">
						全渠道旅游转型，正在面临的七 大痛点！</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				articlelist: [{
						img: require('../assets/img/article1.png'),
						title: '美妆行业数字化转型，颜值经济的私域沉淀',
						time: '2020/8/27 15:37:51',
						lable: '美妆行业，一个刚需、高频的消费市场，并随着消费升级和“颜值经济”的不断驱 动，在国内迎来高速发展。有预测显示，中国美妆市场销售'
					},
					{
						img: require('../assets/img/article2.png'),
						title: '七大行业，搭建私域客户池的重要性',
						time: '2020/8/25 15:56:24',
						lable: '大部分企业将重心从公域流量转移到私域流量，把业务从线下搬到线上，转换运营 思路，为企业的存活找到了一条生路。...'
					},
					{
						img: require('../assets/img/article3.png'),
						title: '私域搭配旅游，全渠道营销就该这么玩！',
						time: '2020/8/24 15:59:35',
						lable: '为什么旅游企业要做私域流量呢，从线上旅游平台、线下门店，获取方式 简单又精准，它不香么？...'
					},
					{
						img: require('../assets/img/article4.png'),
						title: '旅游景区数字化转型，人群精准营销是关键！',
						time: '2020/8/27 15:37:51',
						lable: '目前的旅游消费模式已由卖方市场转向买方市场，旅游方式也由传统的观光旅 游模式转向观光、休闲、度假、户外健身等多元化旅游模式。旅'
					},
					{
						img: require('../assets/img/article5.png'),
						title: '旅游营销4.0的金钥匙，一定要收藏',
						time: '2020/8/19 15:49:43',
						lable: '通过好内容，选择有效的手段，通过覆盖面足够大的渠道，内容+手段+ 渠道=旅游场景营销，通关大门已经打开，跳出传统的品牌营销理念'
					},
					{
						img: require('../assets/img/article6.png'),
						title: '全渠道旅游转型，正在面临的七大痛点！',
						time: '2020/8/27 15:37:51',
						lable: '“智慧旅游”是一个全新的命题，它是一种以物联网、云计算、下一代 通信网络、高性能信息处理、智能数据挖掘等技术在旅游体验、产业发'
					}
				]
			}
		}
	}
</script>

<style lang="scss" scoped>
	@media screen and (max-width: 750px) {
		.Journalism-body{
			width: 90% !important;
		}
		.banner-body {
			width: 95% !important;
			margin: 0 auto;
		}
		
		.banner-body-title {
			width: 50% !important;
		
			.banner-body-title-span {
				width: 100% !important;
			}
		}
		
		.banner-body-img {
			width: 50% !important;
		}
	}
	.pagesbox {
		background-color: #fff;
	}

	.banner {
		width: 100%;
		height: 3.00rem;
		// padding-top: 30px;
		position: relative;
		z-index: 101;

		.banner-body {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			right: 0;

			// padding: 40px 0;
			.banner-body-img {
				width: 35%;
				height: 100%;
				text-align: left;
				justify-content: initial;
			}

			.banner-body-title {
				width: 44%;
				height: 100%;
				// flex-direction: column;
				text-align: left;
				// justify-content: right;
				// align-items: initial;

			}
		}

		// background-image: url(../assets/img/Journalismbanner.png);
	}

	.Journalism-body {
		width: 60%;
		margin: 0.50rem auto;
		align-items: inherit;

		.Journalism-body-l {
			flex: 1;

			.Journalism-body-l-cell {
				width: 100%;
				justify-content: inherit;
				align-items: initial;
				margin-bottom: 0.40rem;

				.Journalism-body-l-cell-r {
					margin-left: 0.20rem;
					text-align: left;
					width: 60%;

					.L-cell-r-tit {
						font-size: 0.13rem;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #333333;
					}

					.L-cell-r-time {
						font-size: 0.08rem;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #999999;
						margin-top: 0.20rem;
					}

					.L-cell-r-but {
						width: 0.8rem;
						height: 0.28rem;
						background-color: #188CF3;
						margin-top: 0.20rem;
						border-radius: 0.05rem;
						line-height: 0.28rem;
						font-size: 0.14rem;
						color: #fff;
						text-align: center;
					}
				}
			}
		}

		.Journalism-body-r {
			width: 1.60rem;

			.Journalism-body-r-tit {
				width: 100%;
				height: 0.30rem;
				line-height: 0.30rem;
				background-color: #070E3E;
				font-size: 0.10rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
			}

			.Journalism-body-r-cent {
				width: 100%;
				background: #F4F4F7;
			}
		}
	}
</style>

<style type="text/css">
	.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
		width: 0.3rem !important;
		min-width: 0.3rem !important;
	}
	
	.el-pagination .btn-next .el-icon, .el-pagination .btn-prev .el-icon{
		font-size: 0.12rem !important;
	}
	.el-pager li{
		font-size: 0.13rem !important;
		height: 0.28rem !important;
		line-height: 0.28rem !important;
	}
	.el-pager .more::before{
		line-height: 0.3rem !important;
	}
	
	.el-pagination button, .el-pagination span:not([class*=suffix]){
		height: 0.28rem !important;
		line-height: 0.28rem !important;
	}
</style>
